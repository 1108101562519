.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.contact-form-dropdown {
  margin-bottom: 30px;
}

.contact-header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-logo {
  width: 50%;
}

.contact-logo-small {
  width: 30%;
}

.contact-header-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-header-text {
  margin: 0;
}

.hidden {
  display: none;
}

.form-width-80-percent {
  width: 80%;
}

.form-width-70-percent {
  width: 70%;
}

.form-width-60-percent {
  width: 60%;
}

.form-width-50-percent {
  width: 50%;
}

.form-width-40-percent {
  width: 40%;
}

.form-width-30-percent {
  width: 30%;
}

.form-width-20-percent {
  width: 20%;
}

.bold-text {
  font-weight: 500;
}

.underline-link {
  text-decoration: underline;
  cursor: pointer;
}
