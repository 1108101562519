:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  display: flex;
}

.pagination-item {
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
}

.pagination-link {
  text-decoration: none;
  padding: 5px;
  color: var(--richBlack);
  border-bottom: solid 1px #000;
}

.active-pagination-item {
  text-decoration: none;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  border-radius: 50%;
}

.active-pagination-link {
  text-decoration: none;
  padding: 5px;
  color: var(--white);
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
  .pagination-container {
    display: flex;
    flex-direction: row;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination-item {
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
  }

  .pagination-link {
    text-decoration: none;
    padding: 5px;
    color: var(--richBlack);
    font-size: 0.7rem;
    border-bottom: solid 1px #000;
  }

  .active-pagination-item {
    text-decoration: none;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-color: var(--manhattan);
    border: solid 1px var(--manhattan);
    border-radius: 50%;
  }

  .active-pagination-link {
    text-decoration: none;
    padding: 5px;
    color: var(--white);
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 600px) {
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .pagination-item {
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
  }

  .pagination-link {
    text-decoration: none;
    padding: 5px;
    color: var(--richBlack);
    font-size: 0.7rem;
    border-bottom: solid 1px #000;
  }

  .active-pagination-item {
    text-decoration: none;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-color: var(--manhattan);
    border: solid 1px var(--manhattan);
    border-radius: 50%;
  }

  .active-pagination-link {
    text-decoration: none;
    padding: 5px;
    color: var(--white);
    font-size: 0.7rem;
  }
}
