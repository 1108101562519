:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.new-balancing-machine-form-container {
  display: flex;
  width: 90%;
  padding: 2%;
}

.new-balancing-machine-form {
  width: 100%;
}

.new-balancing-machine-row {
  width: 100%;
  margin-top: 20px;
}

.nbm-indent-row {
  margin-left: 10px;
  padding: 10px;
  border: solid 1px rgba(162, 160, 160, 0.2);
  border-radius: 10px;
}

.flex-center-row {
  justify-content: center;
}

.new-balancing-machine-col {
  display: flex;
  flex-direction: column;
}

.nbm-form-input {
  min-width: 200px;
  margin-bottom: 5px;
}

.nbm-form-mini-input {
  width: 100px;
  margin-left: 5px;
  margin-right: 5px;
}

.nbm-check-box {
  margin-left: 10px;
}

.nbm-section-heading-text {
  font-weight: 600;
  font-size: 1rem;
}

.nbm-section-text {
  font-weight: 500;
}

.nbm-form-text {
  margin-left: 10px;
}

.italic-text {
  font-style: italic;
}

.nbm-img {
  width: 50%;
}

.nbm-text-content-row {
  display: flex;
  flex-direction: column;
}

.nbm-grouping-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
}

.nbm-submit-btn {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
  width: 150px;
}

.nbm-submit-btn:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattanHover);
  color: var(--richBlack);
  width: 150px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
