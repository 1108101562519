:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

/* --------------- CONTAINERS & WRAPPERS ------------------- */

.stnd-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
}

.stnd-header {
  display: flex;
  align-items: center;
  width: 98%;
}

.stnd-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 600px;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 0.5%;
}

.stnd-top-content {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.stnd-top-content-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.stnd-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stnd-col-start {
  display: flex;
  flex-direction: column;
}

.stnd-middle-content-flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.stnd-middle-content-flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.stnd-bottom-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.stnd-content-wrapper-flex-col {
  display: flex;
  flex-direction: column;
}

.stnd-flex-row-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.stnd-wrap {
  flex-wrap: wrap;
}

.stnd-text-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.stnd-content-wrapper-flex-row {
  display: flex;
  width: 100%;
}

.stnd-subtitle-wrapper {
  width: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stnd-contact-info-wrapper {
  display: flex;
  flex-direction: column;
}

.stnd-right {
  display: flex;
  width: 40%;
}

.stnd-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stnd-filter-controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 80px;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: solid 1px black;
}

.card-flex-column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card-flex-row-wrapper {
  display: flex;
  justify-content: center;
}

.image-button-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.flex-row-center {
  justify-content: center;
}

.flex-column-center {
  align-items: center;
}

/* --------------- TEXT------------------- */

.stnd-h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--richBlack);
}

.stnd-h2 {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--richBlack);
}

.stnd-h3 {
  font-size: 1.15rem;
  font-weight: 500;
  color: var(--richBlack);
}

.stnd-h4 {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--richBlack);
}

.stnd-text {
  font-size: 1rem;
}

.stnd-ul {
  list-style: none;
}

.stnd-center-text {
  text-align: center;
}

.stnd-subtitle {
  font-size: large;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}

.stnd-subtitle-two {
  font-size: medium;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 0px;
}

.stnd-link-text {
  text-decoration: none;
}

.stnd-indent-text {
  margin-left: 2.3rem;
}

.color-blueberry {
  color: var(--blueBerry);
}

/* --------------- BUTTONS------------------- */

.stnd-button-type-one {
  width: 200px;
  padding-left: 5px;
  margin-right: 5px;
}

.stnd-button-type-two {
  width: auto;
  padding-left: 5px;
  margin-right: 5px;
}

.stnd-link-button {
  width: 250px;
}

.ird-products-page-image {
  width: 70%;
}

.ird-products-page-img-link {
  width: 270px;
  height: auto;
  text-decoration: none;
}

/* --------------- IMAGES & ICONS------------------- */

.stnd-image {
  width: 30%;
}

.stnd-image-thirty-percent {
  width: 30%;
}

.stnd-image-fourty-percent {
  width: 40%;
}

.stnd-image-seventy-percent {
  width: 70%;
}

.stnd-icon {
  width: 20px;
  margin-left: 5px;
}

/* ----- MARGINS, PADDING, WIDTH, HEIGHT, & DIV CONTROLS -------- */
.stnd-center-div {
  justify-content: center;
}

.align-center-div {
  align-items: center;
}

.align-start-div {
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
}

.stnd-width-thirty-percent {
  width: 30%;
}

.stnd-width-fourty-percent {
  width: 40%;
}

.stnd-width-fifty-percent {
  width: 50%;
}

.stnd-width-sixty-percent {
  width: 60%;
}

.stnd-width-seventy-percent {
  width: 70%;
}

.stnd-width-eighty-percent {
  width: 80%;
}

.mt-ten-percent {
  margin-top: 10%;
}

.mt-twenty-percent {
  margin-top: 20%;
}

.margin-top-ten {
  margin-top: 10px;
}

.margin-top-twenty {
  margin-top: 20px;
}

.margin-top-thirty {
  margin-top: 30px;
}

.margin-top-fourty {
  margin-top: 40px;
}

.margin-bottom-ten {
  margin-top: 10px;
}

.margin-bottom-thirty {
  margin-bottom: 30px;
}

.stnd-margin-top-bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-left-two-percent {
  margin-left: 2%;
}

.accordion-width-wrapper-eighty-percent {
  width: 80%;
}

/* --------------- SPECIFIC TO BALANCING PAGE------------------ */

.balancing-page-col {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 1%;
}

.bal-img-col {
  position: relative;
}

.balancing-page-col-img {
  position: absolute;
  top: 0px;
  width: calc(50% - 50px);
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border-radius: 50%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
  .balancing-page-col {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    padding: 1%;
  }
}

@media only screen and (max-width: 600px) {
  .balancing-page-col {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10px;
    padding: 1%;
  }

  /* --------------- CONTAINERS & WRAPPERS ------------------- */

  .stnd-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 0;
  }

  .stnd-header {
    display: flex;
    align-items: center;
    width: 98%;
  }

  .stnd-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 600px;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 0.5%;
  }

  .stnd-top-content {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  .stnd-top-content-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  .stnd-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stnd-col-start {
    display: flex;
    flex-direction: column;
  }

  .stnd-middle-content-flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .stnd-middle-content-flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .stnd-bottom-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .stnd-content-wrapper-flex-col {
    display: flex;
    flex-direction: column;
  }

  .stnd-flex-row-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .stnd-wrap {
    flex-wrap: wrap;
  }

  .stnd-text-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .stnd-content-wrapper-flex-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .stnd-subtitle-wrapper {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stnd-contact-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .stnd-right {
    display: flex;
    width: 40%;
  }

  .stnd-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .stnd-filter-controls-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: solid 1px black;
  }

  .card-flex-column-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .card-flex-row-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .image-button-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
  }

  /* --------------- TEXT------------------- */

  .stnd-h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--richBlack);
  }

  .stnd-h2 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--richBlack);
  }

  .stnd-h3 {
    font-size: 1.15rem;
    font-weight: 500;
    color: var(--richBlack);
  }

  .stnd-text {
    font-size: 1rem;
  }

  .stnd-ul {
    list-style: none;
  }

  .stnd-center-text {
    text-align: center;
  }

  .stnd-subtitle {
    font-size: large;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }

  .stnd-subtitle-two {
    font-size: medium;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .stnd-link-text {
    text-decoration: none;
  }

  .stnd-indent-text {
    margin-left: 2.3rem;
  }

  .color-blueberry {
    color: var(--blueBerry);
  }

  /* --------------- BUTTONS------------------- */

  .stnd-button-type-one {
    width: 160px;
    padding-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 0.7rem;
  }

  .stnd-button-type-two {
    width: auto;
    padding-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .stnd-link-button {
    width: 200px;
    font-size: 0.7rem;
  }

  .ird-products-page-image {
    width: 70%;
  }

  .ird-products-page-img-link {
    width: 270px;
    height: auto;
    text-decoration: none;
  }

  /* --------------- IMAGES & ICONS------------------- */

  .stnd-image {
    width: 30%;
  }

  .stnd-image-thirty-percent {
    width: 30%;
  }

  .stnd-image-fourty-percent {
    width: 40%;
  }

  .stnd-image-seventy-percent {
    width: 70%;
  }

  .stnd-icon {
    width: 20px;
    margin-left: 5px;
  }

  /* --------------- MARGINS, PADDING, WIDTH, HEIGHT, & DIV CONTROLS------------------ */
  .stnd-center-div {
    justify-content: center;
  }

  .align-center-div {
    align-items: center;
  }

  .align-start-div {
    align-items: center;
  }

  .flex-start {
    justify-content: flex-start;
  }

  .stnd-width-thirty-percent {
    width: 30%;
  }

  .stnd-width-fourty-percent {
    width: 40%;
  }

  .stnd-width-fifty-percent {
    width: 50%;
  }

  .stnd-width-sixty-percent {
    width: 60%;
  }

  .stnd-width-seventy-percent {
    width: 70%;
  }

  .stnd-width-eighty-percent {
    width: 80%;
  }

  .margin-top-ten {
    margin-top: 10px;
  }

  .margin-top-twenty {
    margin-top: 20px;
  }

  .margin-top-thirty {
    margin-top: 30px;
  }

  .margin-bottom-ten {
    margin-top: 10px;
  }

  .margin-bottom-thirty {
    margin-top: 30px;
  }

  .stnd-margin-top-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .margin-left-two-percent {
    margin-left: 2%;
  }

  .accordion-width-wrapper-eighty-percent {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .balancing-page-col {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10px;
    padding: 1%;
  }
}
