:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.admin-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-left: solid 1px black;
  overflow: scroll;
}

.aiv-data-title-wrapper {
  display: flex;
  border-bottom: solid 1px var(--manhattan);
  margin-bottom: 20px;
}

.aiv-data-title {
  font-size: 1.2rem;
}

.admin-info-content-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.aiv-wrapper {
  display: flex;
}

.aiv-icon-wrapper {
  display: flex;
  justify-content: flex-end;
}

.aiv-icon {
  width: 1.2rem;
  cursor: pointer;
}

.aiv-img-wrapper {
  display: flex;
  justify-content: center;
}

.aiv-sub-title {
  font-size: 1rem;
  color: var(--richBlack);
}

.aiv-sub-title-attention {
  font-size: 1rem;
  color: var(--mexicanRed);
  margin-right: 5px;
}

.aiv-text {
  font-size: small;
}

.aiv-link {
  text-decoration: none;
  font-size: small;
  color: var(--blueBerry);
}

.aiv-img {
  width: 40%;
}

.bottom-border {
  border-bottom: solid 1px #000;
}

.rem-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.rem-col {
  display: flex;
  flex-direction: column;
}

.rem-txt-bld {
  font-size: 0.7rem;
  font-weight: bold;
}

.rem-txt {
  font-size: 0.7rem;
}

.email-sent {
  color: var(--forestGreen);
}

.email-not-sent {
  color: var(--mexicanRed);
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pd-10 {
  padding: 10px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
