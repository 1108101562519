:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.mini-component-container {
  display: flex;
  flex-direction: column;
}

.mini-content-wrapper-flex-row {
  display: flex;
}

.mini-content-wrapper-flex-col {
  display: flex;
  flex-direction: column;
}

.mini-h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.mini-h3 {
  font-size: 1.15rem;
  font-weight: 500;
}

.mini-h4 {
  font-size: 1.1rem;
  font-weight: 500;
}

.mini-p-text {
  font-size: 1rem;
}

.mini-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-image-thirty-percent {
  width: 30%;
}

.mini-image-fourty-percent {
  width: 40%;
}
.mini-image-seventy-percent {
  width: 70%;
}

.mini-ul {
  list-style: none;
}

.mini-li-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.mini-li-text {
  font-size: 1rem;
}

.li-indent {
  margin-left: 10px;
}

.link-text {
  font-size: 1rem;
  text-decoration: none;
  color: var(--blueBerry);
}

.width-thirty-percent {
  width: 30%;
}

.width-fourty-percent {
  width: 40%;
}

.width-fifty-percent {
  width: 50%;
}

.width-sixty-percent {
  width: 60%;
}

.width-seventy-percent {
  width: 70%;
}

.center-text {
  text-align: center;
}

.center-div {
  justify-content: center;
}

.margin-bottom-ten {
  margin-bottom: 10px;
}
