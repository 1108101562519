:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.banner-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15%;
}

.banner-content-wrapper {
  width: 100%;
  backdrop-filter: blur(3px);
}

.banner-h1 {
  color: var(--white);
  font-size: 1.4rem;
}

.banner-text {
  color: var(--white);
  font-size: 1.1rem;
}

.banner-link {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
  width: 150px;
  margin-left: 2%;
  margin-bottom: 2%;
}

.banner-link:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

/* ----------------------- ANIMATION --------------------- */
.banner-animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.banner-delay-1 {
  animation-delay: 0.5s;
}
.banner-delay-2 {
  animation-delay: 0.6s;
}
.banner-delay-3 {
  animation-delay: 0.7s;
}
.banner-delay-4 {
  animation-delay: 0.8s;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 600px) {
  .banner-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15%;
  }

  .banner-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    backdrop-filter: blur(3px);
  }

  .banner-h1 {
    color: var(--white);
    font-size: 1.4rem;
  }

  .banner-text {
    color: var(--white);
    font-size: 1.1rem;
    /* text-align: justify; */
    /* text-justify: inter-word; */
  }

  .banner-link {
    background-color: var(--manhattan);
    border: solid 1px var(--manhattan);
    color: var(--richBlack);
    width: 150px;
    margin-left: 2%;
    margin-bottom: 2%;
  }

  .banner-link:hover {
    background-color: var(--manhattanHover);
    border: solid 1px var(--manhattan);
    color: var(--richBlack);
  }
}

@media only screen and (max-width: 400px) {
}
