:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.clt-features-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: auto;
  max-width: 300px;
  min-height: auto;
  max-height: 220px;
  border: solid 1px black;
  border-radius: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.clt-features-window-title {
  font-size: 1.1rem;
}

.clt-list-title {
  font-size: 1rem;
  font-weight: 400;
}

.clt-list-text {
  font-size: 0.8rem;
}

.indent-list {
  margin-left: 20px;
}

.clt-features-window-list-item {
  font-size: small;
}

.selecting-core-loss-tester-container {
  display: flex;
  width: 100%;
}

.selecting-core-loss-tester-left-side {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.selecting-core-loss-tester-right-side {
  display: flex;
  width: 50%;
}

.selecting-core-loss-tester-text {
  font-size: 0.9rem;
}

.selecting-core-loss-tester-list {
  list-style: none;
}

.selecting-core-loss-tester-img {
  width: 50%;
}

.laptop-printer-container {
  display: flex;
  width: 100%;
}

.laptop-printer-left-side {
  display: flex;

  width: 50%;
}

.laptop-printer-right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.laptop-printer-text {
  font-size: 0.9rem;
}

.laptop-printer-img {
  width: 50%;
}

.core-loss-software-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.core-loss-software-top-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.core-loss-software-header-text {
  font-size: 1.1rem;
  font-weight: 500;
}

.core-loss-software-sub-header-text {
  font-size: 1rem;
  font-weight: 500;
}

.core-loss-software-text {
  font-size: 0.9rem;
}

.core-loss-software-middle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.core-loss-software-bottom-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.info-snippet-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  border: solid 1px black;
  border-radius: 25px;
}

.info-snippet-top-content {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
}

.info-snippet-bottom-content {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.info-snippet-text {
  font-size: 0.9rem;
}

.info-snippet-list {
  list-style: none;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
