.login-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 100vh;
}

.login-page-content {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 600px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
