:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.home-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 100vh;
  background-image: url('../assets/images/ird-large-balance-machine.png');
  background-size: cover;
  background-repeat: no-repeat;
}

/* ---------- MOBILE SECTION ----------- */
@media only screen and (max-width: 600px) {
  .home-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 0;
    min-height: 100vh;
    background-image: url('../assets/images/ird-large-balance-machine.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
