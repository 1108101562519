:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.application-list-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 2%;
  margin-bottom: 2%;
}

.application-list-title {
  color: var(--mexicanRed);
  font-weight: 600;
}

.application-list-row {
  display: flex;
  width: 100%;
}

.application-title-row {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.application-list-guide-row {
  margin-left: 5%;
  margin-right: 5%;
  padding: 1%;
}

.application-list-col {
  display: flex;
  flex-direction: column;
}

.application-list-text {
  color: var(--richBlack);
}

.bold-list-text {
  color: var(--richBlack);
  font-weight: 500;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
