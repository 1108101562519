:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f8ac28;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --blueBerryHover: #2f78f6;
  --aquaMarine: #6cc4b2;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: 5%;
  border: solid 1px black;
  border-radius: 10px;
  margin-top: 5%;
}

.login-text {
  color: #000;
}

.signup-submit-button {
  background-color: var(--blueBerry);
  border: solid 1px var(--blueBerry);
  color: var(--richBlack);
  width: 100px;
}

.signup-submit-button:hover {
  background-color: var(--blueBerryHover);
  border: solid 1px var(--blueBerryHover);
  color: var(--richBlack);
}

.login-submit-button {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
  width: 100px;
}

.login-submit-button:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattanHover);
  color: var(--richBlack);
}

.signout-button {
  background-color: var(--contessa);
  border: solid 1px var(--contessa);
  color: var(--richBlack);
  margin-top: 10%;
  width: 100px;
}

.signout-button:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--canCan);
  color: var(--richBlack);
}

.forgot-password-link {
  margin-top: 10px;
  color: var(--blueBerry);
  cursor: pointer;
}

.change-mode-link {
  margin-top: 10px;
  color: var(--blueBerry);
  cursor: pointer;
}

.login-options-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
