:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.admin-form-title-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 80px;
}

.admin-page-form-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
}

.admin-page-row {
  padding: 20px;
}

.admin-page-col {
  overflow: scroll;
  min-height: 300px;
  max-height: 600px;
  padding: 20px;
}

.admin-page-form-col {
  display: flex;
  justify-content: center;
}

.admin-page-info-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-form-wrapper-text {
  color: var(--richBlack);
  margin-left: 1%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
