.large-modal-container {
  width: 100%;
}

.lm-row {
  display: flex;
  margin-bottom: 20px;
}

.lm-col {
  display: flex;
  flex-direction: column;
}

.lm-flex-row-opt-one {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.lm-content-container {
  display: flex;
  justify-content: space-between;
}

.lm-selected-container {
  display: flex;
  align-items: center;
}

.lm-group-wrapper-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lm-col-flex-row {
  display: flex;
}

.lm-group-wrapper-row {
  display: flex;
  width: 100%;
}

.lm-indent-row {
  margin-left: 10px;
  padding: 10px;
  border: solid 1px rgba(162, 160, 160, 0.2);
  border-radius: 10px;
}

.lm-img-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.lm-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.lm-icon {
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.lm-trash-icon {
  cursor: pointer;
  width: 10%;
  margin-left: 40px;
}

.lm-download-icon {
  cursor: pointer;
  width: 10%;
}

.lm-more-icon {
  cursor: pointer;
  width: 10%;
}

.lm-text {
  font-size: 0.9rem;
}

.bold {
  font-size: 0.9rem;
  font-weight: 500;
}

.lm-h2 {
  font-size: 1.05rem;
  font-weight: 500;
}

.lm-margin-right-25 {
  margin-right: 25px;
}

.lm-margin-right-10 {
  margin-right: 10px;
}

.lm-margin-right-5 {
  margin-right: 5px;
}

.lm-margin-left-25 {
  margin-right: 25px;
}

.lm-margin-left-10 {
  margin-left: 10px;
}

.lm-margin-left-5 {
  margin-left: 5px;
}

.hidden {
  display: none;
}
