:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.service-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
}

.service-page-content {
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  background-image: url(../assets/images/service.jpg);
  background-repeat: no-repeat;
  background-size: 70% 100%;
}

.service-page-header {
  display: flex;
  margin-top: 50px;
  width: 98%;
}

.service-page-header-text {
  color: var(--richBlack);
  margin-left: 2%;
  margin-top: 2%;
}

.service-page-center {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 2%;
}

.service-page-text {
  font-size: small;
}

.service-page-subtitle {
  font-size: large;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0px;
}

.service-page-list {
  text-decoration: none;
}

.service-page-link-text {
  text-decoration: none;
}

.service-indent-text {
  margin-left: 10px;
}

.service-page-icon {
  width: 20px;
  margin-left: 5px;
}

.service-page-contact-info-wrapper {
  display: flex;
  flex-direction: column;
}

.service-page-right {
  display: flex;
  width: 20%;
}

.service-page-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.service-page-image {
  width: 70%;
}

.service-page-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
