:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.new-balance-machine-app-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
}

.new-balance-machine-app-page-header {
  display: flex;
  margin-top: 50px;
}

.new-balance-machine-app-page-header-text {
  font-size: 1.5rem;
  color: var(--richBlack);
  margin-left: 2%;
  margin-top: 2%;
}

.new-balance-machine-app-page-content {
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  overflow: scroll;
  /* outline: solid 1px red; */
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
