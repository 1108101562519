.out-of-service-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0;
  min-height: 100vh;
  color: #000;
}

/* ---------- MOBILE SECTION ----------- */
@media only screen and (max-width: 600px) {
  .home-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 0;
    min-height: 100vh;
  }
}
