:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.image-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 200px;
  padding: 5px;
  margin: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 10px;
  cursor: pointer;
}

.image-button-image {
  width: 80%;
}

.image-button-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--richBlack);
  text-decoration: none;
}

@media only screen and (max-width: 700px) {
  .image-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    height: 110px;
    padding: 5px;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 10px;
    cursor: pointer;
  }

  .image-button-image {
    width: 50%;
  }

  .image-button-text {
    font-size: 0.6rem;
    font-weight: 500;
    color: var(--richBlack);
    text-decoration: none;
  }
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
