:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.content-block {
  width: 95%;
  padding: 2%;
  margin-bottom: 2%;
}

.content-block-row {
  display: flex;
  justify-content: center;
}

.content-block-img-col {
  display: flex;
  justify-content: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.content-block-title {
  color: var(--richBlack);
}

.content-block-image {
  width: 98%;
  padding: 2%;
}

.content-block-image-medium {
  width: 78%;
  padding: 2%;
}

.content-block-image-small {
  width: 58%;
  padding: 2%;
}

.content-block-text {
  font-size: 0.9rem;
  text-align: justify;
  text-justify: inter-character;
}

.content-box-caption-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-caption-text-bold {
  font-weight: 600;
  color: var(--mexicanRed);
}

.image-caption-text {
  color: var(--mexicanRed);
}

.content-block-button {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

.content-block-button:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

.content-block-footer {
  padding: 2%;
}

.content-block-list {
  list-style: none;
}

/* ----------------- REVERSIBLE CONTENT BLOCK ---------------- */
.reversible-content-block {
  width: 95%;
  padding: 2%;
  margin-bottom: 2%;
}

.reversible-content-block-row {
  display: flex;
  justify-content: center;
}

.reversible-content-block-img-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.reversible-content-block-image {
  width: 50%;
  padding: 2%;
}

.reversible-content-block-title {
  color: var(--richBlack);
}

.reversible-content-block-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reversible-content-block-text {
  text-align: justify;
  text-justify: inter-character;
}

.reversible-content-box-caption-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reversible-image-caption-text-bold {
  font-weight: 600;
  color: var(--mexicanRed);
}

.reversible-image-caption-text {
  color: var(--mexicanRed);
}

/* ----------------------- ANIMATION --------------------- */
.content-block-animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade-in;
  animation-timing-function: ease;
  animation-fill-mode: backwards;
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.content-block-delay {
  animation-delay: 0.6s;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
  .reversible-content-block {
    width: 100%;
    padding: 2%;
    margin-bottom: 4%;
  }

  .reversible-content-block-text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .hide-on-mobile {
    display: none;
  }
}
