.osts-container {
  display: flex;
  width: 100%;
}

.osts-left-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 20px;
}

.osts-right-content {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-bottom: 20px;
}

.osts-header-text {
  font-size: 1.1rem;
  font-weight: 500;
}

.osts-text {
  font-size: 0.9rem;
}

.osts-img {
  width: 100%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
