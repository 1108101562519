.crv-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-bottom: solid 2px black;
}

.crv-bold-text {
  font-weight: 600;
}

.crv-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crv-trash-icon {
  cursor: pointer;
  width: 20px;
  margin-left: 40px;
  cursor: pointer;
}
