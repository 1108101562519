:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.accordion-content-row {
  display: flex;
  justify-content: center;
}

.accordion-content-col {
  display: flex;
  flex-direction: column;
}

.accordion-content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
