.job-posting-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 0.5%;
}

.jp-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.jp-description {
  font-size: small;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
