:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
}

.footer-container {
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  height: 200px;
  padding: 0;
}

.footer-content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.footer-row {
  display: flex;
  justify-content: center;
  padding: 2%;
  width: 100%;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-form-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.footer-link-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  width: 98%;
  padding: 2%;
}

.footer-link {
  text-decoration: none;
  color: var(--white);
  font-size: small;
  font-weight: 100;
  margin-left: 8px;
  margin-right: 8px;
}

.footer-link-attention {
  text-decoration: none;
  color: var(--canCan);
  font-size: small;
  font-weight: 100;
  margin-left: 8px;
  margin-right: 8px;
}

.footer-text {
  color: var(--white);
  font-weight: 200;
}

.footer-bold-text {
  font-weight: 400;
}

.footer-contact-section {
  display: flex;
  flex-direction: column;
}

.footer-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer-form-label {
  color: var(--white);
  font-weight: 400;
}

.footer-input-and-button-container {
  display: flex;
  justify-content: space-between;
}

.footer-button {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
  margin-left: 5px;
}

.footer-button:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

@media only screen and (min-width: 1464px) {
  .footer-container {
    display: flex;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    height: 300px;
    padding: 0;
  }
}

@media only screen and (max-width: 760px) {
  .footer-container {
    display: flex;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    height: 400px;
    padding: 0;
  }
}
