:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --blueBerryHover: #3276ed;
  --aquaMarine: #6cc4b2;
}

.featured-product-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 25px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.featured-product-heading-row {
  text-align: center;
  border-bottom: solid 1px black;
}

.featured-product-heading {
  color: var(--richBlack);
  font-weight: 700;
  font-size: 2.5rem;
}

.featured-product-row {
  margin-top: 1rem;
}

.featured-product-image-col {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.featured-product-image {
  width: 95%;
  padding: 5%;
}

.featured-product-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.featured-product-title {
  color: var(--mexicanRed);
  font-weight: 500;
  text-align: justify;
  text-justify: inter-character;
}

.featured-product-text {
  color: black;
  text-align: justify;
  text-justify: inter-character;
}

.featured-product-button {
  background-color: var(--blueBerry);
  border: solid 1px var(--blueBerry);
  color: var(--white);
}

.featured-product-button:hover {
  background-color: var(--blueBerryHover);
  border: solid 1px var(--blueBerryHover);
  color: var(--richBlack);
}

.featured-product-footer {
  padding: 2%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
  .featured-product-text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .featured-product-heading {
    color: var(--richBlack);
    font-weight: 700;
    font-size: 2.2rem;
  }

  .featured-product-title {
    color: var(--mexicanRed);
    font-weight: 400;
    text-align: left;
    text-justify: inter-character;
  }

  .featured-product-text {
    color: black;
    text-align: justify;
    text-justify: inter-character;
  }
}
