.element-container {
  display: flex;
  margin: 10px;
  width: 100%;
}

.element-left-side {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 29%;
  padding: 1%;
}

.element-right-side {
  display: flex;
  flex-direction: column;
  width: 69%;
  padding: 1%;
}

.element-image {
  width: 100px;
  height: 100px;
}

.clickable {
  cursor: pointer;
}

.upgrade-features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(233, 233, 233, 0.8);
}

.upgrade-features-container-inner {
  display: flex;
}
.upgrade-features-left {
  display: flex;
  flex-direction: column;
}

.upgrade-features-right {
  display: flex;
  flex-direction: column;
}

.upgrade-list {
  list-style: disc;
}

.disclaimer-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.small-text {
  margin: 10px;
  font-size: 0.7rem;
}
