:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.table-container {
  width: 100%;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-header-row {
  border-bottom: solid 2px black;
}

.table-header {
  font-size: 1rem;
  font-weight: 500;
  width: 200px;
}

.table-row {
  border-bottom: solid 1px black;
}

.table-options-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pagination-section {
  display: flex;
  align-items: center;
  width: 50%;
}

.search-section {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.table-search-icon-wrapper {
  margin-left: 20px;
}

.table-cell {
  width: auto;
}

.table-text {
  font-size: 0.9rem;
}

.table-link {
  text-decoration: none;
  font-size: 0.9rem;
}

.table-img-wrapper {
  width: 60%;
}

.table-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.table-link-wrapper {
  display: flex;

  align-items: center;
  width: 100%;
}

.table-img {
  width: 100%;
}

.table-icon {
  width: 30px;
  cursor: pointer;
}

.table-search-icon {
  width: 30px;
  cursor: pointer;
}

.table-img:hover {
  transform: scale(1.3);
  margin-left: 2.5rem;
}

.table-icon:hover {
  transform: scale(1.1);
}

.table-button {
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  background-color: var(--manhattan);
  color: var(--richBlack);
  border: solid 1px var(--manhattan);
  border-radius: 50%;
  cursor: pointer;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
  .table-container {
    width: 100%;
  }

  .table-header-row {
    border-bottom: solid 2px black;
  }

  .table-header {
    font-size: 1rem;
    font-weight: 500;
    width: 200px;
  }

  .table-row {
    border-bottom: solid 1px black;
  }

  .table-options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .pagination-section {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .search-section {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .table-search-icon-wrapper {
    margin-left: 20px;
  }

  .table-cell {
    width: auto;
  }

  .table-description-container {
    display: none;
  }

  .table-text {
    font-size: 0.9rem;
  }

  .table-link {
    text-decoration: none;
    font-size: 0.9rem;
  }

  .table-img-wrapper {
    width: 60%;
    display: none;
  }

  .table-img {
    width: 100%;
  }

  .table-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .table-icon {
    width: 20%;
    cursor: pointer;
  }

  .table-search-icon {
    width: 30px;
    cursor: pointer;
  }

  .table-img:hover {
    transform: scale(2);
    margin-left: 2.5rem;
  }

  .table-icon:hover {
    transform: scale(1.1);
  }

  .table-button {
    margin-left: 5px;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    background-color: var(--manhattan);
    color: var(--richBlack);
    border: solid 1px var(--manhattan);
    border-radius: 50%;
    cursor: pointer;
  }
}
