:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.card-component-container-standard {
  display: flex;
  width: 14rem;
  margin: 5px;
}

.card-component-container-large {
  display: flex;
  width: 18rem;
  margin: 5px;
}

.card-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.card-component-body {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-end;
}

.card-sub-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-component-img {
  width: 100%;
}

.card-list-item-wrapper {
  display: flex;
  flex-direction: column;
}

.card-title-text {
  font-size: 1.1rem;
}

.card-subtitle-text {
  color: #787377;
  font-weight: 500;
  font-size: 0.9rem;
}

.card-link {
  font-size: 0.9rem;
}

.card-text-cust {
  font-size: 0.9rem;
}

.card-text-cust-bold {
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration: underline;
}

.card-icon {
  width: 10%;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
