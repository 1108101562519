.mes-container {
  display: flex;
  flex-direction: column;
}

.mes-top-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mes-middle-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mes-bottom-content {
  display: flex;
  flex-direction: column;
}

.mes-header-text {
  font-size: 1.1rem;
  font-weight: 500;
}

.mes-sub-header-text {
  font-size: 1rem;
  font-weight: 500;
}

.mes-text {
  font-size: 0.9rem;
}

.mes-link {
  color: blue;
  text-decoration: none;
  font-size: 0.9rem;
}

.mes-list {
  list-style: none;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
