:root {
  --mexicanRed: #a8223c;
  --manhattan: #fcbf55;
  --manhattanHover: #f3a827;
  --white: #fff;
  --viridianGreen: #94d2bd;
  --blueSaphire: #0a9396;
  --richBlack: #001219;
  --contessa: #c46c7e;
  --canCan: #d48c9c;
  --forestGreen: #328c26;
  --blueBerry: #5592fc;
  --aquaMarine: #6cc4b2;
}

.top-menu-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
}

.top-menu-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px var(--blueBerry);
}

.top-menu-img-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* outline: solid 1px red; */
}

.top-menu-link-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top-menu-img {
  width: 50%;
}

.top-menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  margin-left: 5px;
  margin-right: 5px;
  width: 130px;
  height: 50px;
}

.top-menu-link-version-two {
  margin-left: 10px;
  margin-right: 10px;
}

.top-menu-dropdown {
  margin-left: 5px;
  margin-right: 5px;
  width: 130px;
  height: 50px;
}

.top-menu-link-text {
  text-decoration: none;
  color: black;
}

.top-menu-dropdown-btn {
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 700px) {
  .top-menu-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    border-bottom: solid 1px var(--blueBerry);
  }

  .top-menu-img-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-menu-link-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .top-menu-img {
    width: 30%;
  }

  .top-menu-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 130px;
    height: 50px;
  }

  .top-menu-dropdown-btn {
    margin: 10px;
    width: 120px;
  }

  .top-menu-link-version-two {
    margin: 10px;
    width: 120px;
  }
}
