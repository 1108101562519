.balance-machine-app-data-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  border-bottom: solid 8px black;
}

.bmad-row {
  display: flex;
  margin-bottom: 20px;
}

.bmad-col {
  display: flex;
  flex-direction: column;
}

.bmad-col-flex-row {
  display: flex;
}

.flex-row-opt-one {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.bmad-content-container {
  display: flex;
  justify-content: space-between;
}

.bmad-selected-container {
  display: flex;
  align-items: center;
}

.bmad-group-wrapper-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bmad-group-wrapper-row {
  display: flex;
  width: 100%;
}

.bmad-indent-row {
  margin-left: 10px;
  padding: 10px;
  border: solid 1px rgba(162, 160, 160, 0.2);
  border-radius: 10px;
}

.bmad-img-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.bmad-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.bmad-trash-icon {
  cursor: pointer;
  width: 10%;
  margin-left: 40px;
}

.bmad-download-icon {
  cursor: pointer;
  width: 10%;
}

.bmad-more-icon {
  cursor: pointer;
  width: 10%;
}

.bmad-icon {
  width: 20px;
  margin-right: 5px;
}

.bmad-header-text {
  font-size: 1.1rem;
}

.bmad-text {
  font-size: 1rem;
}

.bmad-bold {
  font-weight: 500;
  font-size: 1rem;
}

.bmad-margin-right-25 {
  margin-right: 25px;
}

.bmad-margin-left-25 {
  margin-right: 25px;
}

.bmad-margin-left-10 {
  margin-left: 10px;
}

.hidden {
  display: none;
}
