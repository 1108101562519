.cs-container {
  display: flex;
  flex-direction: column;
}

.cs-top-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.cs-middle-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.cs-bottom-content {
  display: flex;
  flex-direction: column;
}

.cs-header-text {
  font-size: 1.1rem;
  font-weight: 500;
}

.cs-sub-header-text {
  font-size: 1rem;
  font-weight: 500;
}

.cs-text {
  font-size: 0.9rem;
}

.cs-link {
  color: blue;
  text-decoration: none;
  font-size: 0.9rem;
}

.cs-list {
  list-style: none;
}

.cs-indent {
  margin-left: 10px;
}

/* ----------------------- MOBILE SECTION --------------------- */

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 400px) {
}
